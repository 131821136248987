import React, { useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField'
import CTCB from './copy-to-clipboard-button'

const styles = require('./json-formatter.module.css')

type Props = {

}

function f(input: string): string {
  const json = JSON.parse(input);
  return JSON.stringify(json, null, '\t')
}

const Component: React.FC<Props> = props => {

  const [json, setJson] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const textAreaRef = useRef(null);
  const textIn = useRef(null);

  return (
    <div className={styles.container}>
      <p className={styles.message}>
        {message}
      </p>
      <div className={styles.button}>
        <CTCB target={textAreaRef} />
      </div>
      <TextField
        className={styles.ltxt}
        multiline={true}
        rowsMax={20}
        placeholder="JSON to format"
        variant={"outlined"}
        error={error}
        color={"primary"}
        onChange={(e) => {
          try {
            setMessage("");
            setJson(f(e.target.value));
            setError(false);
          } catch (ex) {
            setMessage(ex.toString());
            setJson("");
            setError(true);
          }
        }} />
      <TextField
        className={styles.rtxt}
        value={json}
        inputRef={textAreaRef}
        multiline={true}
        rowsMax={20}
        variant={"outlined"} />
    </div>
  )
}

export default Component