import React, { MutableRefObject, useRef, useState } from 'react';
import { Button } from '@material-ui/core';

type Props ={
  target: MutableRefObject<any>
  className?: string
}

function copy(target: MutableRefObject<any>) {
  target.current.select();
  document.execCommand('copy');
  target.current.blur();
}

const Component: React.FC<Props> = props => {
  return (
    <Button
      className={props.className}
      onClick={e => copy(props.target)}
      color="default"
      variant="outlined">
      copy to clipboard
    </Button>
  )
}

export default Component