import React from 'react'
import { graphql } from 'gatsby'
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import JSONFormatter from '../../components/json-formatter'

import { JsonFormatterQuery } from '../../../types/graphql-types';

type Props = {
  data: JsonFormatterQuery
}

const Component: React.FC<Props> = props => {
  const location = useLocation()
  const siteTitle = props.data.site.siteMetadata.title

  return (
    <Layout location={location}>
      <div style={{ background: '#fff' }}>
        <Helmet title={`JSON整形ツール | ${siteTitle}`} />
        <div className="wrapper">
          <p>左側のボックスに入力したJSONを整形します。</p>
          <JSONFormatter />
        </div>
      </div>
    </Layout>
  )
}

export default Component

export const pageQuery = graphql`
  query JsonFormatter {
    site {
      siteMetadata {
        title
      }
    }
  }
`